@import '../../../../scss/theme-bootstrap';

.header-nav-section {
  &__section {
    position: relative;
    font-size: 18px;
    width: 100%;
    @include breakpoint($bp--largest-up) {
      font-weight: normal;
      font-size: 14px;
      margin: 0;
      padding: 0 16px;
      min-height: 37px;
      width: auto;
      float: $ldirection;
      display: flex;
      align-items: center;
      &:visited,
      &:active,
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
    &:nth-last-child(2) > a {
      box-shadow: none;
    }
    a {
      &,
      &:visited,
      &:active,
      &:focus,
      &:hover {
        color: $color--black;
        text-transform: none;
        border-bottom-color: transparent;
        @include breakpoint($bp--largest-up) {
          border-bottom-color: $color--black;
        }
      }
      &:hover {
        border-bottom-color: $color--black;
      }
    }
    & > label,
    & > a {
      &,
      &:visited {
        @include single-transition($property: box-shadow);
        border-bottom: none;
        box-shadow: 0 1px 0 0 $color--gray--white;
        @include breakpoint($bp--largest-up) {
          box-shadow: 0 1px 0 0 transparent;
        }
      }
      &:hover {
        @include breakpoint($bp--largest-up) {
          box-shadow: 0 1px 0 0 $color--white;
        }
      }
    }
    .header-nav-section__trigger:checked + & {
      background-color: $color--white;
      display: block;
      @include breakpoint($bp--largest-up) {
        background-color: transparent;
        overflow-y: inherit;
        display: flex;
        top: auto;
        z-index: auto;
      }
    }
    .site-header-formatter__trigger-reset:not(:checked)
      ~ div
      .header-nav-section__trigger:not(:checked)
      + & {
      display: none;
      @include breakpoint($bp--largest-up) {
        display: flex;
      }
    }
    &-row-link {
      color: $color--black;
      cursor: pointer;
      line-height: $gnav-mobile-link-height;
      text-decoration: none;
      height: $gnav-mobile-link-height;
      margin: 0 $gnav-mobile-hpad--outer;
      border-bottom: 1px solid $color--gray--white;
      @include breakpoint($bp--largest-up) {
        box-shadow: none;
        line-height: initial;
        height: auto;
        margin: 0;
        border-bottom: none;
      }
      .header-nav-section__trigger:checked ~ .header-nav-section__section & {
        position: initial;
        box-shadow: none;
        text-align: center;
      }
      .header-nav-section__section-label {
        &,
        &:visited,
        &:active,
        &:focus,
        &:hover {
          @include breakpoint($bp--largest-up) {
            color: $color--white;
          }
        }
        .header-nav-section__trigger:checked ~ .header-nav-section__section & {
          text-align: center;
          font-size: 22px;
          font-family: $ano-bold_regular-font;
          @include breakpoint($bp--largest-up) {
            font-family: $ano-black_regular-font;
            font-size: 14px;
          }
        }
      }
      .header-nav-section__mobile-menu-icon {
        .ada-enabled &,
        .js.ada-enabled &,
        .no-js & {
          @include breakpoint($bp--largest-up) {
            fill: $color--white;
            display: block;
            transform: rotate(90deg);
            height: 14px;
          }
        }
        .js & {
          display: none;
        }
      }
    }
    &-label {
      @include set-link-colors(
        $color--black,
        $color--black,
        $color--black,
        $color--black,
        $color--black
      );
      @include set-underline-links(
        transparent,
        $color--black,
        transparent,
        transparent,
        transparent,
        1px,
        false
      );
      cursor: pointer;
      align-items: center;
      font-family: $ano-black_regular-font;
      @include breakpoint($bp--largest-up) {
        @include set-link-colors(
          $color--white,
          $color--white,
          $color--white,
          $color--white,
          $color--white
        );
        @include set-underline-links(
          transparent,
          $color--white,
          transparent,
          transparent,
          transparent,
          1px,
          false
        );
        color: $color--white;
        height: auto;
        margin: 0;
      }
      &:hover {
        color: $color--black;
        text-decoration: none;
        border-bottom-style: none;
        @include breakpoint($bp--largest-up) {
          box-shadow: 0 1px 0 0 $color--black;
        }
      }
      &--arrow {
        display: flex;
        opacity: 1;
        position: absolute;
        top: 0;
        #{$rdirection}: 22px;
        @include breakpoint($bp--largest-up) {
          top: 18px;
          #{$rdirection}: 0;
        }
      }
      &--text {
        @include set-link-colors(
          $color--black,
          $color--black,
          $color--black,
          $color--black,
          $color--black
        );
        @include breakpoint($bp--largest-up) {
          display: flex;
          color: $color--white;
          line-height: normal;
        }
        &:hover,
        &:focus {
          color: $color--black;
          text-decoration: none;
          @include breakpoint($bp--largest-up) {
            color: $color--white;
          }
        }
        svg {
          .header-nav-section__trigger:checked ~ .header-nav-section__section & {
            display: none;
          }
        }
      }
      .header-nav-section__trigger:checked + .header-nav-section__section & {
        @include breakpoint($bp--largest-up) {
          box-shadow: 0 1px 0 0 $color--black;
          text-decoration: none;
        }
      }
    }
  }
  &__section-toggle {
    display: none;
    @include breakpoint($bp--largest-up) {
      position: fixed;
      #{$rdirection}: unset;
      #{$ldirection}: 8px;
      top: calc(#{$gnav-height} + 8px);
      z-index: $gnav-fixed-z-index;
      cursor: pointer;
      width: $gnav-icon--inline;
      height: $gnav-icon--inline;
      svg {
        width: 100%;
        height: inherit;
      }
    }
    .ada-enabled &,
    .no-js & {
      @include breakpoint($bp--largest-up) {
        display: block;
      }
    }
  }
  &__section-content {
    background-color: $color--white;
    display: none;
    height: 0;
    #{$ldirection}: 0;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    width: 100%;
    @include breakpoint($bp--largest-up) {
      @include transition(height 0.25s ease, opacity 0.25s ease, visibility 0.25s ease);
      background-color: $color--black;
      z-index: $gnav-fixed-z-index;
      top: $gnav-height;
      overflow: hidden;
      position: fixed;
      display: block;
    }
    // expanded state
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      display: block;
      height: auto;
      opacity: 1;
      position: relative;
      visibility: visible;
      overflow: hidden;
      @include breakpoint($bp--largest-up) {
        position: fixed;
        visibility: visible;
        z-index: $gnav-fixed-z-index;
        background-color: $color--white;
        overflow: visible;
      }
      .gnav-section-group > div:nth-child(1) {
        .gnav-section-formatter__content-wrapper {
          @include breakpoint($bp--largest-up) {
            opacity: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
        .gnav-section-formatter__link:hover ~ .gnav-section-formatter__content-wrapper {
          @include breakpoint($bp--largest-up) {
            opacity: 1;
          }
        }
      }
    }
  }
  &__mobile-menu-icon {
    height: $gnav-mobile-link-height;
    width: 8px;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
    &:hover,
    &:active {
      fill: $color--black;
    }
    // Arrow displays for mobile expanders only.
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      opacity: 0;
      .ada-enabled &,
      .no-js & {
        @include breakpoint($bp--largest-up) {
          opacity: 1;
        }
      }
    }
  }
  &__arrow-icon {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s ease, visibility 0.25s ease;
    .header-nav-section__trigger:checked + .header-nav-section__section & {
      @include breakpoint($bp--largest-up) {
        width: 0;
        height: 0;
        bottom: 0;
        #{$ldirection}: 50%;
        visibility: visible;
        position: absolute;
        opacity: 1;
        transform: translateX(-50%);
        border-#{$ldirection}: 10px solid transparent;
        border-#{$rdirection}: 10px solid transparent;
        border-bottom: 10px solid $color--white;
      }
    }
  }
}

// GNAV simplified on all checkout pages, excluding the cart page.
body {
  &#index,
  &#samples,
  &#shipping,
  &#billing,
  &#review,
  &#confirm {
    .header-nav-section {
      display: none;
    }
  }
}

.content-block-sitewide-banner--enabled {
  #colorbox.welcome15-overlay,
  .homepage-popup {
    display: none !important;
  }
  .header-nav-section__section-content {
    @include breakpoint($bp--largest-up) {
      top: $gnav-height + $content-block-signup-height;
    }
  }
  .cr21-refresh.site-header-formatter {
    .header-nav-section {
      &__trigger:checked + .header-nav-section__section + label.site-header-formatter-overlay {
        @include breakpoint($bp--largest-up) {
          top: $gnav-height + 12px + $content-block-signup-height;
        }
      }
    }
  }
}
